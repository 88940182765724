import React, { Component } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
//import Img from "gatsby-image/withIEPolyfill"
import {unorphan} from "../components/utilities"

/*
const PeopleList = ({ people, allPeople }) => (

  <div className="people">
    {people.map(person => (
      <div key={person} >
        <Link to={"/people/" + allPeople.edges.filter(({ node }) => node.wordpress_id === person)[0].node.slug}>
          {allPeople.edges.filter(({ node }) => node.wordpress_id === person)[0].node.name}
        </Link>
      </div>
    ))}
  </div>
)
*/



class NewsPageTemplate extends Component {
  constructor(props) {
    super(props);
    this.currentPage = this.props.data.wordpressPost


  }



  render() {
    const currentPage = this.currentPage
    return (
      <div className="news_page">

        <Helmet>
          <meta charSet="utf-8" />
          <title>{currentPage.title }</title>
          <link
            rel="canonical"
            href={"https://grmandco.com/news/" + currentPage.slug}
          />
        </Helmet>


        <h1 class="page_title" title={currentPage.title} dangerouslySetInnerHTML={{ __html: unorphan(currentPage.title) }} />
        <div class="main_content">
        


<div class="news_content" title={currentPage.title} dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        </div>
      </div>
    )
  }
}

export default NewsPageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      people
      slug
      acf {
        news_image {
          id
          
        }
      }
    }
    allWordpressWpPeople {
      edges {
        node {
          id
          name
          slug
          wordpress_id
        }
      }
    }
  }
`
